import React, { useState, useEffect, useRef } from "react"
import Lottie from "react-lottie"
import { Waypoint } from "react-waypoint"
import Beebo from "../../assets/animation/beebo.json"

const DoAllContent = ({ setRefsHeight }) => {
  let [animateDoitall, setAnimateDoitall] = useState(false)
  let [animateDescription, setAnimateDescription] = useState(false)
  const ref = useRef(null)

  const beebo = {
    loop: false,
    autoplay: true,
    animationData: Beebo,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {
    setRefsHeight(refsHeight => ({
      ...refsHeight,
      doAllContent: ref.current.offsetHeight,
    }))
  }, [setRefsHeight])

  return (
    <div ref={ref} className="section-doitall">
      <div className="content-wrapper">
        <div className="container">
          <div className="text-holder">
            <Waypoint onEnter={() => setAnimateDoitall(true)} />
            <div className="beebo">
              {animateDoitall && (
                <Lottie
                  options={beebo}
                  resizeMode="cover"
                  width={709}
                  height={400}
                />
              )}
            </div>
            <p className="title">Do it all on Servbees</p>
            <Waypoint onEnter={() => setAnimateDescription(true)} />
            {animateDescription && <div className="description"></div>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DoAllContent
