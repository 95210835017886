import React, { useEffect, useRef } from "react"
import SupportImage from "../../assets/images/sec_connection-kv 1.png"
import MobileSupportImage from "../../assets/images/sec_connection-kv-mobile-min.png"

const SupportContent = ({ setRefsHeight }) => {
  const ref = useRef(null)

  useEffect(() => {
    setRefsHeight(refsHeight => ({
      ...refsHeight,
      supportContent: ref.current.offsetHeight,
    }))
  }, [setRefsHeight])

  return (
    <div ref={ref} id="Scroll" className="section-beehive">
      <div className="container">
        <p className="title">
          Get the full <strong>Servbees</strong> support to be the best in your
          biz
        </p>
        <img
          className="image desktop-beehive-image"
          src={SupportImage}
          alt=""
        />
        <img
          className="image mobile-beehive-image"
          src={MobileSupportImage}
          alt=""
        />
      </div>
    </div>
  )
}

export default SupportContent
