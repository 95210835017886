import React, { useState, useEffect } from "react"
import Beebo from "../assets/images/beebo.svg"
import Arrow from "../assets/images/arrow-down.svg"
import {trackDownloadWithPixel} from './../services/pixels.service'

const SectionDownload = () => {
  //link depending on what device
  const [link, setLink] = useState()
  const [type, setType] = useState()

  useEffect(() => {
    if (/iPhone|iPad|iPod|Opera Mini/i.test(navigator.userAgent)) {
      setLink("https://apps.apple.com/ph/app/servbees/id1530137634")
      setType('ios')
    } else {
      setLink("https://play.google.com/store/apps/details?id=com.servbees")
      setType('android')
    }
  }, [])

  return (
    <div className="section-download">
      <div className="container">
        <div className="content-holder">
          <div className="bee-holder">
            <img className="image" src={Beebo} alt="Beebo" />
          </div>
          <div className="text-holder">
            <h3 className="title">
              Connect, support, and grow along with fellow sellers, freelancers,
              entrepreneurs, and Buzzybees of all stripes on Servbees.
            </h3>
            <p className="text">
              Together, let's build a rewarding hustle community. One with less
              barriers, more opportunities for all.
            </p>
          </div>
          <div className="download-holder">
            <a
              href={link}
              className="btn btn-download"
              target="__blank"
              onClick={() => trackDownloadWithPixel(type)}>
              Download now
            </a>
            <img className="scroll-arrow" src={Arrow} alt="Beebo" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SectionDownload
