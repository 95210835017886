import React, { useState, useEffect, useRef } from "react"
import Lottie from "react-lottie"
import { Parallax } from "react-scroll-parallax"
import Bake from "../../assets/images/get-buzzy__bake.svg"
import Cloud from "../../assets/images/get-buzzy__cloud.svg"
import Coffee from "../../assets/images/get-buzzy__coffee.svg"
import Laptop from "../../assets/images/get-buzzy__laptop.svg"
import Food from "../../assets/images/get-buzzy__food.svg"
import Laundry from "../../assets/images/get-buzzy__laundry.svg"
import Instrument from "../../assets/images/get-buzzy__instrument.svg"
import { Waypoint } from "react-waypoint"
import Fade from "react-reveal/Fade"
import animationPinCircle from "../../assets/animation/pin-circle.json"

const ProductiveContent = ({ setRefsHeight }) => {
  const ref = useRef(null)
  let [animateBuzzy, setAnimateBuzzy] = useState(false)

  const pinCirle = {
    loop: false,
    autoplay: true,
    animationData: animationPinCircle,
    rendererSettings: {
      className: "pinpoint",
      preserveAspectRatio: "xMidYMid slice",
    },
  }

  useEffect(() => {
    setRefsHeight(refsHeight => ({
      ...refsHeight,
      productiveContent: ref.current.offsetHeight,
    }))
  }, [setRefsHeight])

  return (
    <div ref={ref} className="section-get-buzzy slide">
      <div className="container">
        <Fade>
          <div className="animation-holder">
            <Waypoint onEnter={() => setAnimateBuzzy(true)} />
            {animateBuzzy && (
              <>
                <h2 className="title">Get buzzy and productive</h2>
                <Parallax y={[-15, 15]} tagOuter="figure">
                  <Lottie
                    options={pinCirle}
                    resizeMode="cover"
                    width={830}
                    height={830}
                  />
                  <div className="popup-images">
                    <img className="pop-object" src={Cloud} alt="cloud" />
                    <img className="pop-object" src={Cloud} alt="cloud" />
                    <img className="pop-object" src={Bake} alt="bake" />
                    <img className="pop-object" src={Coffee} alt="coffee" />
                    <img className="pop-object" src={Laptop} alt="laptop" />
                    <img className="pop-object" src={Cloud} alt="cloud" />
                    <img className="pop-object" src={Cloud} alt="cloud" />
                    <img className="pop-object" src={Food} alt="food" />
                    <img className="pop-object" src={Laundry} alt="laundry" />
                    <img
                      className="pop-object"
                      src={Instrument}
                      alt="instrument"
                    />
                  </div>
                </Parallax>
              </>
            )}
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default ProductiveContent
