import React, { useState, useEffect, useRef } from "react"
import ScrollAnimation from "react-animate-on-scroll"
import Fade from "react-reveal/Fade"
import SearchIcon from "../../assets/images/search-icon.svg"
import ScanQrIcon from "../../assets/images/scan-qr-icon.svg"
import FilterIcon from "../../assets/images/filter-icon.svg"
import LikeIcon from "../../assets/images/heart-icon.svg"

const HelpContent = ({ setRefsHeight }) => {
  const ref = useRef(null)
  const [search, setSearch] = useState("")

  const handleSearchChange = event => {
    setSearch(event.currentTarget.value)
  }

  useEffect(() => {
    setRefsHeight(refsHeight => ({
      ...refsHeight,
      helpContent: ref.current.offsetHeight,
    }))
  }, [setRefsHeight])

  return (
    <div ref={ref} className="section-help slide">
      <div className="container">
        <Fade bottom>
          <div>
            <h2 className="title">Servbees can help you</h2>
            <ScrollAnimation
              initiallyVisible={true}
              animateIn="description"
              animateOnce={true}
            ></ScrollAnimation>
          </div>
          <div className="search-wrapper">
            <div className="search-bar">
              <input
                className="search"
                type="search"
                value={search}
                disabled
                onChange={handleSearchChange}
              />
              <div className="typing-placeholder">
                {/* <ScrollAnimation
                  initiallyVisible={true}
                  animateIn="placeholder"
                  animateOnce={true}
                >
                  Search...
                </ScrollAnimation> */}
                <ScrollAnimation
                  initiallyVisible={true}
                  animateIn="typing-text"
                  animateOnce={true}
                >
                  Search...
                </ScrollAnimation>
              </div>
              <img className="search-icon" src={SearchIcon} alt="" />
              <img className="scan-qr-icon" src={ScanQrIcon} alt="" />
            </div>
            <div className="button-holder">
              <button className="btn btn-filter">
                <img src={FilterIcon} alt="" />
              </button>
              <button className="btn btn-like">
                <img src={LikeIcon} alt="" />
              </button>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

export default HelpContent
