import React, { useState, useEffect, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import SellIcon from "../assets/images/icon-sell.svg"
import NeedIcon from "../assets/images/icon-need.svg"
import ServiceIcon from "../assets/images/icon-service.svg"
import FSell from "../assets/images/card_sell-min.png"
import FNeed from "../assets/images/card_need-min.png"
import FService from "../assets/images/card_service-min.png"

const swiperContent = [
  {
    icon: ServiceIcon,
    title: "Offer Services",
    desc: "Get your hustle on and earn from your skills and talents.",
    backgroundColor: "#e9636c",
    backgroundSrc: FService,
    marquee:
      "Electrician Repairs Sanitation Plumbing Bento Boxes Gardening Pet-sitting Technician Tutor PasabuyRental Photography Training Workshop Tailor Rental Carpentry Cleaning Construction Delivery",
  },
  {
    icon: SellIcon,
    title: "Sell Items",
    desc: "Market new products, pre-loved goods, and grow your business.",
    backgroundColor: "#2d53b4",
    backgroundSrc: FSell,
    marquee:
      "Ube Pandesal Plants Stickers Cold Brew Cactus Kombucha Vitamins Books Painting Sushi Bake Face Masks Skincare Natural Remedies Coffee Beans Gadgets Tumblers Baking Tools Gaming Mouse Containers Cake",
  },
  {
    icon: NeedIcon,
    title: "Find Deals",
    desc:
      "Post what you’re looking for and get great options within your area.",
    backgroundColor: "#16c09c",
    backgroundSrc: FNeed,
    marquee:
      "Custom Furniture House Repairs Car Detailing Donations Catering Supplier Event Host Teacher Interior Designer Painter Make-up Artist Accountant Tailor Upholstering Baker Custom Pots Woodwork IT Support Online Tutor Driver",
  },
]

const CardsSlider = ({ setRefsHeight }) => {
  const [index, setIndex] = useState(1)
  const bgColor = useRef(null)
  const title = useRef(null)
  const desc = useRef(null)
  const marquee = useRef(null)
  const icon = useRef(null)

  useEffect(() => {
    bgColor.current.style.backgroundColor = swiperContent[index].backgroundColor
    icon.current.src = swiperContent[index].icon
    title.current.innerText = swiperContent[index].title
    desc.current.innerText = swiperContent[index].desc
    marquee.current.innerText = swiperContent[index].marquee

    setRefsHeight(refsHeight => ({
      ...refsHeight,
      cardSlider: bgColor.current.offsetHeight,
    }))
  }, [index, setRefsHeight])

  return (
    <>
      <div ref={bgColor} className="section-cards">
        <div className="container">
          <div className="heading-wrapper">
            <div className="title-holder">
              <div className="icon">
                <img ref={icon} className="img-fluid" alt="" />
              </div>
              <span className="title" ref={title}></span>
            </div>
            <p className="desc" ref={desc}></p>
          </div>
          <div className="swiper-cards">
            <Swiper
              loop={true}
              speed={1000}
              slidesPerView={2}
              centeredSlides={true}
              autoplay={true}
              effect="coverflow"
              freeMode={false}
              coverflowEffect={{
                rotate: 0,
                slideShadows: false,
              }}
              updateOnWindowResize={true}
              breakpoints={{
                1200: {
                  direction: "horizontal",
                  coverflowEffect: {
                    stretch: 580,
                    depth: 245,
                    modifier: 2,
                  },
                },
                992: {
                  direction: "horizontal",
                  coverflowEffect: {
                    stretch: 600,
                    depth: 400,
                    modifier: 2,
                  },
                },
                768: {
                  direction: "horizontal",
                  coverflowEffect: {
                    stretch: 450,
                    depth: 300,
                    modifier: 2,
                  },
                },
                576: {
                  direction: "vertical",
                  loopAdditionalSlides: 3,
                  coverflowEffect: {
                    stretch: 157,
                    depth: 185,
                    modifier: 3,
                  },
                },
                473: {
                  direction: "vertical",
                  coverflowEffect: {
                    stretch: 116,
                    depth: 115,
                    modifier: 3,
                  },
                },
                320: {
                  direction: "vertical",
                  coverflowEffect: {
                    stretch: 76,
                    depth: 80,
                    modifier: 3,
                  },
                },
              }}
              onResize={swiper => {
                swiper.update()
                swiper.updateSize()
              }}
              onSlideChange={swiper => {
                setIndex(swiper.realIndex)
              }}
              onSwiper={swiper => {
                swiper.updateSlides()
              }}
            >
              {swiperContent.map((item, i) => {
                return (
                  <SwiperSlide key={i}>
                    <div
                      className="card-holder"
                      style={{ backgroundImage: `url(${item.backgroundSrc})` }}
                    ></div>
                  </SwiperSlide>
                )
              })}
            </Swiper>
          </div>
          <div className="marquee-holder">
            <div className="marquee" ref={marquee}></div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CardsSlider
