import React, { useState, useEffect } from "react"

//plugins
import { Waypoint } from "react-waypoint"
import addToMailchimp from "gatsby-plugin-mailchimp"

//components
import SignupModal from "../../components/newsletter/signupModal"
import SuccessModal from "../../components/newsletter/successModal"

// assets
import modalImg from "../../assets/images/illustration-signupsuccess.svg"
import Foreground from "../../assets/images/newsletter-foreground.svg"

const BeUpdatedContent = () => {
  //animation
  const [animateForeground, setAnimateForeground] = useState(false)

  //for newsletter
  const [email, setEmail] = useState("")
  const [access, setAccess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (access || success === true) {
      document.body.style.overflow = "vissible"
    } else {
      document.body.style.overflow = "unset"
    }
  })

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email)
      .then(data => {
        // alert(data.msg)
        if (data.result === "error") {
          setErrorMessage(`This email is already subscribed to the newsletter`)
          return
        } else {
          setSuccess(!success)
          setAccess(false)
          setErrorMessage("")
        }
      })
      .catch(error => {
        alert("Check email address")
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const showModal = () => {
    setAccess(!access)
  }

  const closeSuccess = () => {
    setSuccess(false)
  }

  return (
    <>
      <div className="section-bottom">
        <div className="container about-wrapper">
          <div className="container content-holder">
            <h3 className="bottom-title">We are Servbees</h3>
            <p className="bottom-desc">
              Your friendly neighborhood PAGKAKAKITA-APP!
            </p>
            <br />
            <button className="btn" type="submit" onClick={showModal}>
              Bee Updated
            </button>
          </div>
          <div className="container content-holder" id="module">
            <Waypoint onEnter={() => setAnimateForeground(true)} />
            <img
              src={Foreground}
              alt=""
              className={`foreground${animateForeground ? "-animate" : ""}`}
              id="Foreground"
            />
          </div>
        </div>
      </div>
      {access ? (
        <SignupModal
          errorMessage={errorMessage}
          showModal={showModal}
          handleSubmit={handleSubmit}
          handleEmailChange={handleEmailChange}
          access={access}
          success={success}
          inputValue={email}
        />
      ) : (
        ""
      )}
      {success ? (
        <SuccessModal
          handleSubmit={handleSubmit}
          access={access}
          success={success}
          closeSuccess={closeSuccess}
          modalImg={modalImg}
          mainText={"What the buzz! You're now on our email list."}
          subText={"Check your email and get buzzing with Servbees!"}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default BeUpdatedContent
