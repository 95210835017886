import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from "../components/footer"
import CardsSlider from "../components/cardsSlider"
import SupportContent from "../components/landing/supportContent"
import DoItAllContent from "../components/landing/doAllContent"
import BeUpdatedContent from "../components/landing/beUpdatedContent"
import HeroContent from "../components/landing/heroContent"
import SectionHelp from "../components/landing/helpContent"
import SectionProductive from "../components/landing/productiveContent"
import HorizontalScroll from "../components/horizontal-scroll"
import Thumbnail from "../assets/images/thumbnail.jpg"

const Index = () => {
  const [refsHeight, setRefsHeight] = useState({})

  return (
    <>
      <Layout
        showFooter={false}
        className="landing-page bg-design vector-dash-yellow"
      >
        <SEO
          pageTitle="Home"
          title="Servbees: Bee Part of the Newest Community Commerce App!"
          description="Join Servbees and bee ready to sell, offer services, and find what you need within your community."
          imageUrl={Thumbnail}
        />
        <HeroContent setRefsHeight={setRefsHeight} />
        <SupportContent setRefsHeight={setRefsHeight} />
        <SectionHelp setRefsHeight={setRefsHeight} />
        <SectionProductive setRefsHeight={setRefsHeight} />
        <CardsSlider setRefsHeight={setRefsHeight} />
        <DoItAllContent setRefsHeight={setRefsHeight} />
        <HorizontalScroll refsHeight={refsHeight} />
        <BeUpdatedContent />
        <Footer />
      </Layout>
    </>
  )
}

export default Index
